import './styles.css';
import arrow from '../../assets/arrow.svg';
import social_large from '../../assets/social_large.svg';
import ru from '../../assets/flags/ru.svg';
import en from '../../assets/flags/us.svg';
import Button from '../../ui-kit/Button';

const Social = ({ onClose }: { onClose(): void }) => {
  return (
    <div className="social_container">
      <img
        src={arrow}
        style={{ marginTop: '11px' }}
        height={46}
        onClick={onClose}
      />
      <div className="social_content">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2>Become an ambassador</h2>
          <img src={social_large} height={63} />
        </div>
        <p>
          Become an ambassador of EMCD TMA and help others discover the best
          money exchange services available.
        </p>
        <Button
          pattern="primary"
          onClick={() =>
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLSf3hcCAHYV2WF4URmreIpJrfdBxbo4aFnbN9z39q3V5gOW00w/viewform?usp=sf_link',
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          Become an ambassador
        </Button>
        <h2 style={{ textAlign: 'center', width: '100%', marginTop: '32px' }}>
          Join to our community
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '17px',
            width: '100%',
          }}
        >
          <div style={{ position: 'relative', width: '100%' }}>
            <Button
              pattern="secondary"
              onClick={() =>
                window.open(
                  'https://t.me/EMCDUniverseRU',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              Ru
            </Button>
            <div className="flag_box">
              <img src={ru} />
            </div>
          </div>
          <div style={{ position: 'relative', width: '100%' }}>
            <Button
              pattern="secondary"
              onClick={() =>
                window.open(
                  'https://t.me/EMCDUniverseEng',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              En
            </Button>
            <div className="flag_box">
              <img src={en} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
