import Card from '../../components/Card';
import { ProfileType } from './types';
import './styles/Profile.css';
import Header from '../../components/Header';
import Button from '../../ui-kit/Button';
import speakers from '../../assets/3d_speakers.png';
import location from '../../assets/location.png';
import ru from '../../assets/flags/ru.svg';
import en from '../../assets/flags/us.svg';
import { NewProgressBar } from '../../components/NewProgressBar';
import { EnergyButton } from '../../components/EnergyButton';

const Profile: ProfileType = ({
  data,
  onOpenInfo,
  onOpenRoadmap,
  photoUrl,
  name,
  handleInvite,
  handleShowNotification,
  onOpenCharging,
  timeToStart,
  timeToEnd,
  isCharging,
  chargedValue,
  isChargeCompleted,
}) => {
  return (
    <div className="profile_container">
      <Header photoUrl={photoUrl} name={name} />
      <Card data={data} onOpenInfo={onOpenInfo} />
      <NewProgressBar
        data={data}
        currentValue={data?.Level?.ActualPoints || 0}
        maxValue={data?.Level?.PointsToNextLevel || 0}
        handleInvite={handleInvite}
        handleShowNotification={handleShowNotification}
      />
      <EnergyButton
        currentValue={chargedValue}
        isCharging={isCharging}
        timeToStart={timeToStart ?? ''}
        timeToEnd={timeToEnd ?? ''}
        onOpenCharging={onOpenCharging}
        isChargeCompleted={isChargeCompleted}
      />
      <div className="bottom_buttons_container">
        <div className="ambassador_buttons_left">
          <Button
            className="ambassador_button"
            onClick={() =>
              window.open(
                'https://docs.google.com/forms/d/e/1FAIpQLSf3hcCAHYV2WF4URmreIpJrfdBxbo4aFnbN9z39q3V5gOW00w/viewform?usp=sf_link',
                '_blank',
                'noopener,noreferrer'
              )
            }
            pattern="secondary"
            styles={{
              backgroundImage: `url(${speakers})`,
              backgroundPosition: 'right',
              backgroundSize: '45%',
              backgroundRepeat: 'no-repeat',
            }}
          >
            Become an ambassador
          </Button>
          <Button
            className="ambassador_button"
            onClick={onOpenRoadmap}
            pattern="secondary"
            styles={{
              backgroundImage: `url(${location})`,
              backgroundPosition: '100%',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          >
            Roadmap
          </Button>
        </div>
        <div className="ambassador_buttons_right">
          <div className="ambassador_buttons_right_title">
            Join to our community
          </div>
          <div className="ambassador_buttons_right_box_container">
            <button
              className="ambassador_buttons_right_box"
              onClick={() =>
                window.open(
                  'https://t.me/EMCDUniverseRU',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              <div className="ambassador_buttons_right_box_container_title">
                Ru
              </div>
              <div className="ambassador_buttons_right_box_container_flag">
                <img src={ru} />
              </div>
            </button>
            <button
              className="ambassador_buttons_right_box"
              onClick={() =>
                window.open(
                  'https://t.me/EMCDUniverseEng',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              <div className="ambassador_buttons_right_box_container_title">
                En
              </div>
              <div className="ambassador_buttons_right_box_container_flag">
                <img src={en} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
